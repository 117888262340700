html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Source Code Pro', monospace;
  font-size: large;
  line-height: 1.6;
  color: #eee;
  background-color: #06131a;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  color: rgb(171, 227, 241);
  text-decoration: underline;
}

a:visited {
  color: rgb(171, 227, 241);
}


.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  align-items: center;
}

.App-header {
  padding: 0.3rem;
  padding-left: 0.7rem;
  display: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.9rem;
  color: white;
}

.App-header a {
  color: rgb(171, 227, 241);
  text-decoration: underline;
}

.App-header a:visited {
  color: rgb(171, 227, 241);
}

.App-footer {
  padding: 20px;
  text-align: left;
  width: 100%;
}
.content {
  flex: 1;
}

.wrapper {
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  padding: 20px;
}

.sliders {  
  display: flex;
  flex-direction: column; 
  align-items: flex-start;
  width: 50%;
  max-width: 40rem;
}

.phrases {
  align-items: center;
  width: 60%;
  min-width: fit-content;
  max-width: 55rem;
}

.siteTitle {
  font-size: 2rem;
  font-weight: normal;
  margin-bottom: 0px;
}

.siteTagline {
  font-size: large;
}

.phrasesHeader {
  margin-top: 2rem;
  justify-self: left;
}

.phrase {
  position: relative;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', Courier, monospace;
  font-size: large;
  background-color: #353533;
  color: #faf1f1;
  padding: 8px 12px;
  padding-right: 2.0rem;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #969393;
  white-space: pre-wrap;
  word-wrap: break-word;
  display: block;
}

.copy-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.refresh-icon {
  margin-left: 0.36em;
  color: #50504d;
}

.refresh-icon :hover {
  color: rgb(171, 227, 241);
}

footer {
  color:  #999;
  margin-left: 2rem;
}

.copyright {
  margin-top: 10px;
  font-size: 0.9rem;
}